import axios from "axios";
import IUser from "@interfaces/IUser";
import { getConfigHeaders } from "@configs/axios";

const API_BASE_URL = "/api/user";

export const getUser = async (id: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const isUserIncomplete = async (id: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/is-user-incomplete/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/all`, getConfigHeaders());
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const createUser = async (userData: IUser) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      userData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updateUser = async (userData: IUser) => {
  try {
    const { id } = userData;
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      userData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (uid: string) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/${uid}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
