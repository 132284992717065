import React from "react";
import { Menu, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import {
  ShopOutlined,
  TeamOutlined,
  CrownOutlined,
  LogoutOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

const MenuComponent: React.FC<any> = () => {
  const navigate = useNavigate();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const menuItems = [
    { key: "stamps", label: "Sellos", icon: CrownOutlined },
    { key: "services", label: "Servicios", icon: ProfileOutlined },
    { key: "business", label: "Barberías", icon: ShopOutlined },
    { key: "users", label: "Usuarios", icon: TeamOutlined },
    { key: "logout", label: "Logout", icon: LogoutOutlined },
  ];

  const items: any = menuItems.map((item) => ({
    key: item.key,
    label: item.label,
    icon: React.createElement(item.icon),
  }));

  const onMenuItemClick = (menu: any) => {
    if (menu.key === "logout") {
      onConfirmLogout();
      return;
    }
    navigate(menu.key);
  };

  const onConfirmLogout = () => {
    confirm({
      title: `Logout`,
      centered: true,
      content: `Desea hacer logout?`,
      okText: "Logout",
      okType: "primary",
      cancelText: "No",
      onOk() {
        onLogout();
      },
    });
  };

  const onLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate("/");
    } catch (error: any) {
      message.error("Error signing out:", error);
    }
  };

  return (
    <div style={{ height: "88%" }}>
      {contextHolder}
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{
          height: "100%",
          justifyContent: "space-between",
        }}
        items={items}
        onClick={onMenuItemClick}
      />
    </div>
  );
};

export default MenuComponent;
