import { Form, Select } from "antd";
import { codes } from "@constants/countryCodes";

const { Option } = Select;

interface IPhonePrefixProps {
  disabled?: boolean;
}

const PhonePrefix: React.FC<IPhonePrefixProps> = (props) => (
  <Form.Item noStyle name="phonePrefix" initialValue="+504">
    <Select
      style={{ width: 90 }}
      disabled={props.disabled}
      optionLabelProp="label"
      popupMatchSelectWidth={false}
    >
      {Object.values(codes)?.map((code: any) => (
        <Option key={code.value} value={code.value} label={code.value}>
          {code.name} ({code.value})
        </Option>
      ))}
    </Select>
  </Form.Item>
);
export default PhonePrefix;
