import { useEffect, useRef, useState } from "react";
import { MAP_BOX_API } from "@constants/globalVariables";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = MAP_BOX_API;

const MapboxMap = ({ coordinates, zoom, setCoordinates }: any) => {
  const mapContainer = useRef(null);
  const [map, setMap]: any = useState();
  const [currentCoordinates, setCurrentCoordinates]: any = useState({
    lng: coordinates.lng,
    lat: coordinates.lat,
    zoom: zoom,
  });

  useEffect(() => {
    const map = getMap();
    map.addControl(new mapboxgl.NavigationControl());
    setMap(map);
    const marker = new mapboxgl.Marker();
    marker.setLngLat([coordinates.lng, coordinates.lat]).addTo(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  const updateCoordinates = () => {
    const center = map.getCenter();
    const zoom = map.getZoom();
    setCurrentCoordinates({ lng: center.lng, lat: center.lat, zoom });
  };

  const getMap = () => {
    return new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [currentCoordinates.lng, currentCoordinates.lat],
      zoom: currentCoordinates.zoom,
    });
  };

  map?.on("move", updateCoordinates);
  map?.on("click", (e: any) => {
    const { lng, lat } = e.lngLat;
    setCoordinates({ lng, lat });
  });

  return (
    <div
      ref={mapContainer}
      style={{ width: "800px", height: "550px", marginBottom: 20 }}
    />
  );
};

export default MapboxMap;
