export interface IActionProps {
  label: string;
  dataObject: any;
  onDelete: Function;
  onConfirm?: Function;
  disabled: boolean;
  showEdit: boolean;
  showDelete: boolean;
}

export interface IStampProps {
  dataObject: any;
  onReload: Function;
}

export interface ITableToolbarProps {
  onFilterChange: Function;
  canCreate: boolean;
  properties?: any;
}

export const formItemLayout = {
  labelCol: {
    sm: { span: 0 },
  },
};

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    sm: { offset: 6 },
  },
};

export const getRoleColor = (status: string) => {
  switch (status) {
    case "Admin":
      return "gold";
    case "Cliente":
      return "#2db7f5";
  }
};
