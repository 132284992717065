import { Image, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { ref, getStorage, getDownloadURL } from "firebase/storage";

interface IImageThumbnailProps {
  image: any;
  folder: string;
}

const ImageThumbnail: React.FC<IImageThumbnailProps> = ({
  image,
  folder,
}: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (!image) return;
    const storage = getStorage();
    const imageRef = ref(storage, `${folder}/${image[0]?.uid}`);

    getDownloadURL(imageRef)
      .then((url) => {
        setImageUrl(url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error getting image:", error);
      });
  }, [image, folder]);

  return (
    <>
      {image && imageUrl && !isLoading ? (
        <Image
          width={"100%"}
          height={"100%"}
          src={imageUrl}
          alt={image[0].name}
        />
      ) : (
        <Skeleton.Image style={{ width: 100 }} />
      )}
    </>
  );
};
export default ImageThumbnail;
