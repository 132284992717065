import React from "react";
import { Card, Layout } from "antd";

const { Content } = Layout;

const Terms: React.FC = () => {
  return (
    <Card>
      <Layout style={{ padding: 20 }}>
        <Content>
          <div>
            <h1>Términos y Condiciones de Mostacho App</h1>
            <p>Fecha de Entrada en Vigor: 10 de noviembre del 2023</p>

            <p>
              Bienvenido a Mostacho, la aplicación móvil de barbería
              desarrollada por Wasabi. Al utilizar esta aplicación, aceptas
              cumplir con los siguientes Términos y Condiciones. Lee atentamente
              antes de usar la aplicación.
            </p>

            <h2>1. Información Recopilada</h2>

            <h3>1.1 Información que Recopilamos</h3>
            <p>
              No recopilamos dirección, teléfono ni ubicación. Recopilamos
              nombre, apellido, correo electrónico y fecha de nacimiento.
            </p>

            <h3>1.2 Fotos de Usuario</h3>
            <p>
              La aplicación puede recopilar fotos del usuario. Estas fotos son
              exclusivamente para la foto de perfil y son seleccionadas por el
              usuario.
            </p>

            <h2>2. Sistema de Lealtad</h2>
            <p>
              La aplicación incluye un sistema de lealtad. Con cada visita a la
              barbería, se acumulan sellos. Después de alcanzar un número
              específico de sellos, el cliente puede recibir un servicio
              gratuito.
            </p>

            <h2>3. Notificaciones</h2>
            <p>
              La aplicación utiliza notificaciones para enviar información
              relevante, como recordatorios de citas, actualizaciones del
              sistema de lealtad y promociones exclusivas.
            </p>

            <h2>4. Elección de Foto de Perfil</h2>
            <p>
              El usuario puede elegir la foto de perfil utilizando la cámara o
              la galería de imágenes de su teléfono. Esta elección es
              completamente voluntaria.
            </p>

            <h2>5. Dispositivos y Plataformas Compatibles</h2>
            <p>
              La aplicación está disponible para dispositivos Android y iOS,
              tanto para teléfonos como para tabletas.
            </p>

            <h2>6. Uso de la Cámara por Parte del Empleado</h2>
            <p>
              Los empleados de la barbería tienen la opción de utilizar la
              cámara únicamente para escanear códigos QR relacionados con la
              aplicación Mostacho.
            </p>

            <h2>7. Servicios de Terceros</h2>
            <p>
              La aplicación utiliza servicios de autenticación proporcionados
              por terceros, como Apple y Google, para facilitar el proceso de
              inicio de sesión. Al optar por utilizar estos servicios, los
              usuarios aceptan los términos y condiciones de los respectivos
              proveedores de servicios externos.
            </p>

            <h2>8. Eliminación de Cuenta</h2>
            <p>
              Los usuarios tienen el derecho de eliminar sus cuentas y datos
              personales dentro de la aplicación en cualquier momento. Para
              eliminar su cuenta, siga los pasos proporcionados en la
              configuración de la aplicación o póngase en contacto con nuestro
              equipo de soporte para obtener asistencia.
            </p>

            <h2>9. Contacto</h2>
            <p>
              Para cualquier pregunta o consulta, contáctanos en{" "}
              <a href="mailto:info@wasabihn.com">info@wasabihn.com</a>.
            </p>

            <h2>10. Cambios en Estos Términos y Condiciones</h2>
            <p>
              Nos reservamos el derecho de actualizar estos Términos y
              Condiciones en cualquier momento. La fecha de la última revisión
              se indicará en la parte superior de los términos.
            </p>

            <p>
              Al utilizar la aplicación Mostacho, aceptas estos Términos y
              Condiciones. Si no estás de acuerdo, por favor, no utilices la
              aplicación.
            </p>
          </div>
        </Content>
      </Layout>
    </Card>
  );
};

export default Terms;
