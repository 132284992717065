import dayjs from "dayjs";

import { DAY } from "@constants/weekDays";
import { TIME_FORMAT } from "@constants/date";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Space,
  TimePicker,
} from "antd";

const Schedule = (props: any) => {
  Form.useWatch("schedule", props.form);

  const getCheckboxClass = (name: any, day: any) => {
    const schedule = props?.form?.getFieldValue("schedule")[name];
    if (!schedule) return;
    const isSelected = schedule[day];
    return `schedule-checkbox ${isSelected ? "schedule-checkbox-pressed" : ""}`;
  };

  const getDayRadioButton = (
    restField: any,
    name: any,
    day: string,
    activeByDefault: boolean
  ) => (
    <Form.Item
      {...restField}
      name={[name, day]}
      valuePropName="checked"
      initialValue={activeByDefault}
    >
      <Checkbox className={getCheckboxClass(name, day)} value>
        {day}
      </Checkbox>
    </Form.Item>
  );

  const getTimePicker = (restField: any, name: any) => (
    <Form.Item
      {...restField}
      name={[name, "time"]}
      initialValue={[
        dayjs("08:00 AM", TIME_FORMAT),
        dayjs("05:00 PM", TIME_FORMAT),
      ]}
    >
      <TimePicker.RangePicker
        format={TIME_FORMAT}
        use12Hours
        inputReadOnly
        allowClear={false}
        minuteStep={30}
      />
    </Form.Item>
  );

  const ifSingleSchedulesExist = (fields: any) => {
    return fields.length === 1;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Form.List name="schedule">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Form.Item
                key={index}
                style={{
                  width: 1000,
                  marginBottom: 0,
                }}
              >
                <Row style={{ justifyContent: "center" }}>
                  <Col>
                    <Space.Compact id={`space-${index}`}>
                      {getDayRadioButton(
                        restField,
                        name,
                        DAY.MONDAY,
                        ifSingleSchedulesExist(fields)
                      )}
                      {getDayRadioButton(
                        restField,
                        name,
                        DAY.TUESDAY,
                        ifSingleSchedulesExist(fields)
                      )}
                      {getDayRadioButton(
                        restField,
                        name,
                        DAY.WEDNESDAY,
                        ifSingleSchedulesExist(fields)
                      )}
                      {getDayRadioButton(
                        restField,
                        name,
                        DAY.THURSDAY,
                        ifSingleSchedulesExist(fields)
                      )}
                      {getDayRadioButton(
                        restField,
                        name,
                        DAY.FRIDAY,
                        ifSingleSchedulesExist(fields)
                      )}
                      {getDayRadioButton(restField, name, DAY.SATURDAY, false)}
                      {getDayRadioButton(restField, name, DAY.SUNDAY, false)}
                    </Space.Compact>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Col style={{ marginRight: 20 }}>
                    <Space.Compact>
                      {getTimePicker(restField, name)}
                    </Space.Compact>
                  </Col>

                  <Col>
                    {fields.length > 1 ? (
                      <Button danger ghost onClick={() => remove(name)}>
                        Remover
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <Divider style={{ marginTop: 0 }} />
              </Form.Item>
            ))}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                block
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Agregar horario
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Schedule;
