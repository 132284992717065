import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "@configs/firebase";

import "./index.css";
import App from "./App";

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

reportWebVitals();
