import axios from "axios";
import { getConfigHeaders } from "@configs/axios";

const API_BASE_URL = "/api/stamp";

export const getStamps = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/all`, getConfigHeaders());
    return response.data;
  } catch (error) {
    console.error("Error fetching stamps:", error);
    throw error;
  }
};

export const createStamp = async (uid: string, quantity: number) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/create/${uid}/${quantity}`,
      {},
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating stamp:", error);
    throw error;
  }
};
