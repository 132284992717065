export const breadcrumbNameMap: Record<string, string> = {
  "/stamps": "Sellos",
  "/stamps/form": "sello",
  "/services": "Servicios",
  "/services/form": "servicio",
  "/business": "Barberías",
  "/business/form": "Barbería",
  "/users": "Usuarios",
  "/users/form": "usuario",
};
