import axios from "axios";
import IBusiness from "@interfaces/IBusiness";
import { getConfigHeaders } from "@configs/axios";

const API_BASE_URL = "/api/business";

export const getBusiness = async (id: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching business:", error);
    throw error;
  }
};

export const getBusinesses = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/all`, getConfigHeaders());
    return response.data;
  } catch (error) {
    console.error("Error fetching businesses:", error);
    throw error;
  }
};

export const createBusiness = async (businessData: IBusiness) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      businessData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating business:", error);
    throw error;
  }
};

export const updateBusiness = async (businessData: IBusiness) => {
  try {
    const { id } = businessData;
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      businessData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating business:", error);
    throw error;
  }
};

export const deleteBusiness = async (id: string) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting business:", error);
    throw error;
  }
};
