import axios from "axios";
import { getConfigHeaders } from "@configs/axios";

const API_BASE_URL = "/api";

export const verifyAuth = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/verify-auth`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying authorization:", error);
    throw error;
  }
};
