import React, { Key, useEffect, useState } from "react";
import IStamp from "@interfaces/IStamp";
import TableToolbar from "@components/Table/TableToolbar";

import { getStamps } from "@services/stampService";
import { Table, Layout, Card, message } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";
import { getDateDifference, getDateTimeString } from "@utils/date";

const { Header, Content } = Layout;

const Stamps: React.FC = () => {
  const [data, setData] = useState<IStamp[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IStamp>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const stampData = await getStamps();
      debugger
      setData(stampData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      message.error(error?.response?.data);
    }
  };

  const columns: ColumnsType<IStamp> = [
    {
      key: "stampAt",
      title: "Fecha de sello",
      dataIndex: "stampAt",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.stampAt, b.stampAt),
      sortOrder: sortedInfo.columnKey === "stampAt" ? sortedInfo.order : null,
      render: (stampAt) => getDateTimeString(stampAt),
    },
    {
      key: "name",
      title: "Cliente",
      dataIndex: "name",
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "quantity",
      title: "Cantidad",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === "quantity" ? sortedInfo.order : null,
      render: (quantity) => quantity,
    },
    {
      key: "",
      title: "Creado por",
      dataIndex: "",
      sorter: (a, b) => getDateDifference(a.stampAt, b.stampAt),
      sortOrder: sortedInfo.columnKey === "stampAt" ? sortedInfo.order : null,
      render: (stamp) => stamp.metadata.createdBy,
    },
  ];

  const handleTableChange: TableProps<IStamp>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IStamp>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default Stamps;
