import React, { useEffect, useState } from "react";
import IService from "@interfaces/IService";
import IFieldData from "@interfaces/IFieldData";
import FileUpload from "@components/Common/FileUpload";

import { getDataWithImage } from "@utils/util";
import { useLocation, useNavigate } from "react-router-dom";
import { buttonLayout, formLayout } from "@constants/formLayout";
import { createService, updateService } from "@services/serviceService";
import {
  Form,
  Spin,
  Card,
  Input,
  Space,
  Button,
  Switch,
  message,
  InputNumber,
} from "antd";

import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

const { TextArea } = Input;

const ServiceForm: React.FC<IFormProps> = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const service: IService = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadService = () => {
    if (!service) return;
    setLoading(true);
    const serviceData = getFieldData(service);
    setFields(serviceData);
    setLoading(false);
  };

  const goBack = () => navigate("..");

  const onFinish = async (serviceData: IService) => {
    try {
      setLoading(true);
      const data = getDataWithImage(serviceData);
      if (data?.id) {
        await updateService(data);
        message.success("Servicio actualizado!");
      } else {
        data.position = location?.state?.properties?.newPosition;
        await createService(data);
        message.success("Servicio creado!");
      }
      setLoading(false);
      goBack();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card>
        <Form
          form={form}
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="position" hidden />

          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input className="input-class" maxLength={50} />
          </Form.Item>

          <Form.Item
            name="description"
            label="Descripción"
            rules={[{ required: true }]}
          >
            <TextArea rows={6} maxLength={300} />
          </Form.Item>

          <Form.Item
            name="duration"
            label="Duración"
            rules={[{ required: true }]}
            initialValue={30}
          >
            <InputNumber controls={true} min={1} addonAfter="min." />
          </Form.Item>

          <Form.Item
            name="price"
            label="Precio"
            rules={[{ required: true }]}
            initialValue={0}
          >
            <InputNumber controls={true} min={0} addonAfter="lps." />
          </Form.Item>

          <Form.Item
            name="redeemable"
            label="Redimible"
            rules={[{ required: true }]}
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <FileUpload
            folder="service"
            form={form}
            image={service && service.image}
          />
          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {service?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default ServiceForm;
