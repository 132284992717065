import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "@pages/Login";
import Privacy from "@pages/Privacy";
import PrivacyEn from "@pages/Privacy/privacyEn";
import Terms from "@pages/Terms";

import Services from "@pages/Services";
import ServiceForm from "@pages/Services/ServiceForm";
import Stamps from "@pages/Stamps";
import StampForm from "@pages/Stamps/StampForm";
import Users from "@pages/Users";
import UserForm from "@pages/Users/UserForm";
import Business from "@pages/Business";
import BusinessForm from "@pages/Business/BusinessForm";

export const PublicContent: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="privacy">
        <Route index element={<Privacy />} />
      </Route>
      <Route path="privacyEn">
        <Route index element={<PrivacyEn />} />
      </Route>
      <Route path="terms">
        <Route index element={<Terms />} />
      </Route>
    </Routes>
  );
};

export const PrivateContent: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Services />} />
      <Route path="services">
        <Route index element={<Services />} />
        <Route path="form" element={<ServiceForm />} />
      </Route>
      <Route path="stamps">
        <Route index element={<Stamps />} />
        <Route path="form" element={<StampForm />} />
      </Route>
      <Route path="users">
        <Route index element={<Users />} />
        <Route path="form" element={<UserForm />} />
      </Route>
      <Route path="business">
        <Route index element={<Business />} />
        <Route path="form" element={<BusinessForm />} />
      </Route>
    </Routes>
  );
};
