import React, { useState } from "react";
import { Card, Form, Input, message, Image, Button, Spin } from "antd";
import { onFinishFailed } from "@components/Form/form";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onFinish = async (userData: any) => {
    const { username, password } = userData;
    try {
      setLoading(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, username, password);
      setLoading(false);
    } catch (error: any) {
      message.error("Invalid email or password");
      setLoading(false);
    }
  };

  const onResetPassword = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      message.success("Se envió un correo para resetear contraseña");
      setLoading(false);
    } catch (error: any) {
      if (error.code === "auth/invalid-email") {
        message.error("Correo invalido!");
      } else if (error.code === "auth/user-not-found") {
        message.error("Usuario no existe!");
      } else {
        message.error(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <div className="login">
        <Card className="login-card" title={null}>
          <h1>Mostacho Barber Shop</h1>
          <div style={{ textAlign: "center" }}>
            <Image
              width={250}
              height={250}
              preview={false}
              src="./mostacho.png"
            />
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                className="input-class"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password className="input-class" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Continuar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                onClick={onResetPassword}
                style={{ width: "100%" }}
              >
                ¿Has olvidado la contraseña?
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

export default Login;
