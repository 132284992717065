import React from "react";
import { Card, Layout } from "antd";

const { Content } = Layout;

const Privacy: React.FC = () => {
  return (
    <Card>
      <Layout style={{ padding: 20 }}>
        <Content>
          <div>
            <h1>Política de Privacidad de Mostacho Barber Shop App</h1>
            <p>Fecha de Entrada en Vigor: 10 de noviembre del 2023</p>
            <p>
              Bienvenido a Mostacho, la aplicación móvil de barbería
              desarrollada por Wasabi. En Wasabi, nos comprometemos a proteger
              tu privacidad y garantizar que tu información personal esté
              segura. Esta Política de Privacidad describe cómo recopilamos,
              utilizamos y compartimos tu información cuando utilizas la
              aplicación Mostacho.
            </p>
            <h2>1. Información que Recopilamos</h2>
            <h3>1.1 Información que Proporcionas</h3>
            <p>
              Cuando utilizas la aplicación Mostacho, recopilamos la siguiente
              información:
            </p>
            <ul>
              <li>Nombre y Apellido.</li>
              <li>Correo Electrónico.</li>
              <li>Fecha de Nacimiento.</li>
              <li>
                Foto del Usuario (a través de la cámara o la galería de imágenes
                de sus dispositivos).
              </li>
            </ul>
            <h3>1.2 Información del Sistema de Lealtad</h3>
            <p>
              La aplicación Mostacho incluye un sistema de lealtad que acumula
              sellos con cada visita a la barbería. La información relacionada
              con el sistema de lealtad puede incluir la frecuencia de las
              visitas y la acumulación de sellos.
            </p>
            <h2>2. Cómo Utilizamos Tu Información</h2>
            <p>
              Utilizamos la información recopilada para los siguientes
              propósitos:
            </p>
            <ul>
              <li>
                Prestar y mejorar nuestros servicios de barbería a través de la
                aplicación Mostacho.
              </li>
              <li>
                Personalizar tu experiencia y proporcionar recomendaciones de
                estilos de corte de cabello.
              </li>
              <li>
                Gestionar el sistema de lealtad y proporcionar beneficios a los
                clientes que acumulan un número determinado de sellos.
              </li>
              <li>
                Permitir a los usuarios elegir fotos de perfil utilizando la
                cámara o la galería de imágenes de sus dispositivos.
              </li>
            </ul>

            <h2>3. Escaneo de Códigos QR por Empleados</h2>
            <p>
              Los empleados del negocio tienen la opción de utilizar la cámara
              exclusivamente para escanear códigos QR relacionados con la
              aplicación Mostacho, por ejemplo, para gestionar el sistema de
              lealtad o procesar pagos.
            </p>

            <h2>4. Compartir y Divulgar Información</h2>
            <p>
              No compartiremos tu información personal con terceros, excepto en
              las siguientes situaciones:
            </p>
            <ul>
              <li>Con tu consentimiento explícito.</li>
              <li>Para cumplir con obligaciones legales.</li>
              <li>
                En relación con una transacción comercial, como una fusión o
                adquisición.
              </li>
            </ul>

            <h2>5. Acceso y Control de tu Información</h2>
            <p>
              Puedes acceder y actualizar tu información personal, incluyendo la
              foto de perfil, a través de la aplicación Mostacho. También puedes
              gestionar tus preferencias de notificación y privacidad en la
              configuración de la aplicación.
            </p>

            <h2>6. Seguridad</h2>
            <p>
              Implementamos medidas de seguridad para proteger tu información
              contra accesos no autorizados y divulgación.
            </p>

            <h2>7. Servicios de Terceros</h2>
            <p>
              La aplicación utiliza servicios de autenticación proporcionados
              por terceros, como Apple y Google, para facilitar el proceso de
              inicio de sesión. Al optar por utilizar estos servicios, los
              usuarios aceptan los términos y condiciones de los respectivos
              proveedores de servicios externos.
            </p>

            <h2>8. Eliminación de Cuenta</h2>
            <p>
              Los usuarios tienen el derecho de eliminar sus cuentas y datos
              personales dentro de la aplicación en cualquier momento. Para
              eliminar su cuenta, siga los pasos proporcionados en la
              configuración de la aplicación o póngase en contacto con nuestro
              equipo de soporte para obtener asistencia.
            </p>

            <h2>9. Cambios en Esta Política de Privacidad</h2>
            <p>
              Nos reservamos el derecho de actualizar esta Política de
              Privacidad en cualquier momento. La fecha de la última revisión se
              indicará en la parte superior de la política.
            </p>
            <h2>10. Contáctanos</h2>
            <p>
              Si tienes preguntas sobre esta Política de Privacidad, puedes
              contactarnos en{" "}
              <a href="mailto:info@wasabihn.com">info@wasabihn.com</a>.
            </p>
          </div>
        </Content>
      </Layout>
    </Card>
  );
};

export default Privacy;
