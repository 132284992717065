import MapboxMap from "@components/Common/MapBoxMap";
import { defaultCoordinates } from "@constants/coordinates";

const MapLocation = (props: any) => {
  return (
    <MapboxMap
      coordinates={props.coordinates}
      zoom={defaultCoordinates.zoom}
      setCoordinates={props.setCoordinates}
    />
  );
};

export default MapLocation;
