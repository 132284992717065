import { Form, Button, Upload } from "antd";
import { useState } from "react";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { ref, getStorage, uploadBytes, deleteObject } from "firebase/storage";

import ImageThumbnail from "./ImageThumbnail";

interface IFileUploadProps {
  form: any;
  image: any;
  folder: string;
}

const FileUpload: React.FC<IFileUploadProps> = ({
  form,
  image,
  folder,
}: any) => {
  const storage = getStorage();
  const [previewImage, setPreviewImage] = useState<any>(image);

  const customRequest = async (props: any) => {
    const imagesRef = ref(storage, `${folder}/${props.file.uid}`);
    await uploadBytes(imagesRef, props.file);
    setPreviewImage([{ uid: props.file.uid, name: props.file.name }]);
  };

  const normFile = (e: any) => e?.fileList;

  const handleDelete = () => {
    if (!previewImage) return;
    const imageRef = ref(storage, `${folder}/${previewImage[0].uid}`);

    deleteObject(imageRef)
      .then(() => {
        setPreviewImage(null);
        form.setFieldValue("image", null);
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };

  return (
    <>
      <Form.Item
        label="Imagen"
        name="image"
        getValueFromEvent={normFile}
        style={{ marginBottom: 10, marginTop: 0 }}
      >
        <Upload
          maxCount={1}
          multiple={false}
          accept="image/*"
          showUploadList={false}
          customRequest={customRequest}
        >
          <Button type="primary" icon={<UploadOutlined />}>
            Subir Imagen
          </Button>
        </Upload>
      </Form.Item>

      {previewImage && (
        <>
          <Form.Item label=" ">
            <Button icon={<DeleteOutlined />} onClick={handleDelete}>
              Borrar Imagen
            </Button>
          </Form.Item>

          <Form.Item
            label=" "
            style={{
              marginBottom: 10,
            }}
          >
            <div style={{ width: 200, marginRight: 20 }}>
              <ImageThumbnail folder={folder} image={previewImage} />
            </div>
          </Form.Item>
        </>
      )}
    </>
  );
};
export default FileUpload;
