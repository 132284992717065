import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import IBusiness from "@interfaces/IBusiness";
import IFieldData from "@interfaces/IFieldData";
import Schedule from "@components/Business/Schedule";
import MapLocation from "@components/Business/MapLocation";
import Information from "@components/Business/Information";

import { TIME_FORMAT } from "@constants/date";
import { getDataWithImage } from "@utils/util";
import { defaultCoordinates } from "@constants/coordinates";
import { useLocation, useNavigate } from "react-router-dom";
import { buttonLayout, formLayout } from "@constants/formLayout";
import { createBusiness, updateBusiness } from "@services/businessService";
import { Tabs, Form, Space, Button, Spin, Card, message } from "antd";
import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

const BusinessForm: React.FC<IFormProps> = (props) => {
  const [form] = Form.useForm();
  const [selectedBusiness, setSelectedBusiness] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const business: IBusiness = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(defaultCoordinates.center);

  useEffect(() => {
    loadBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBusiness = () => {
    if (!business) return;
    setLoading(true);
    setSelectedBusiness(business);
    setCoordinates(business.coordinates);
    formatScheduleTimeToDayJs(business);

    const businessData = getFieldData(business);
    setFields(businessData);
    setLoading(false);
  };

  const goBack = () => navigate("..");

  const formatScheduleTimeToDayJs = (business: any) => {
    if (!business?.schedule) return;
    business?.schedule?.forEach((schedule: any) => {
      schedule.time = [
        dayjs(schedule.startTime, TIME_FORMAT),
        dayjs(schedule.endTime, TIME_FORMAT),
      ];
    });
  };

  const formatScheduleTime = (businessData: IBusiness) => {
    businessData?.schedule?.forEach((schedule: any) => {
      schedule.startTime = schedule.time[0].format(TIME_FORMAT);
      schedule.endTime = schedule.time[1].format(TIME_FORMAT);
      delete schedule.time;
    });
  };

  const onFinish = async (businessData: IBusiness) => {
    try {
      setLoading(true);

      let data = Object.assign(selectedBusiness, businessData);
      data.coordinates = coordinates;
      formatScheduleTime(data);
      data = getDataWithImage(data);

      if (business?.id) {
        await updateBusiness(data);
        message.success("Barbería actualizada!");
      } else {
        await createBusiness(data);
        message.success("Barberías creada!");
      }

      setLoading(false);
      goBack();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data);
    }
  };

  const informationTab = () => ({
    label: "Información",
    card: <Information form={form} image={business && business.image} />,
  });

  const scheduleTab = () => ({
    label: "Horario",
    card: <Schedule form={form} />,
  });

  const mapTab = () => {
    return {
      label: "Mapa",
      card: (
        <MapLocation
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      ),
    };
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card>
        <Form
          form={form}
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            items={[informationTab(), scheduleTab(), mapTab()].map(
              (detail, i) => {
                const id = String(i + 1);
                return {
                  label: detail.label,
                  key: id,
                  children: detail.card,
                };
              }
            )}
          />

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {business?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default BusinessForm;
