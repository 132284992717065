import React from "react";
import { Card, Layout } from "antd";

const { Content } = Layout;

const PrivacyEn: React.FC = () => {
  return (
    <Card>
      <Layout style={{ padding: 20 }}>
        <Content>
          <div>
            <h1>Privacy Policy of Mostacho Barber Shop App</h1>
            <p>Effective Date: November 10, 2023</p>
            <p>
              Welcome to Mostacho, the mobile barbering application developed by
              Wasabi. At Wasabi, we are committed to protecting your privacy and
              ensuring that your personal information is secure. This Privacy
              Policy describes how we collect, use, and share your information
              when you use the Mostacho application.
            </p>
            <h2>1. Information We Collect</h2>
            <h3>1.1 Information You Provide</h3>
            <p>
              When you use the Mostacho application, we collect the following
              information:
            </p>
            <ul>
              <li>First and Last Name.</li>
              <li>Email Address.</li>
              <li>Date of Birth.</li>
              <li>User Photo (via camera or device gallery).</li>
            </ul>
            <h3>1.2 Loyalty System Information</h3>
            <p>
              The Mostacho application includes a loyalty system that
              accumulates stamps with each visit to the barbershop. Information
              related to the loyalty system may include visit frequency and
              stamp accumulation.
            </p>
            <h2>2. How We Use Your Information</h2>
            <p>We use the collected information for the following purposes:</p>
            <ul>
              <li>
                Provide and improve our barbering services through the Mostacho
                application.
              </li>
              <li>
                Personalize your experience and provide haircut style
                recommendations.
              </li>
              <li>
                Manage the loyalty system and provide benefits to customers who
                accumulate a certain number of stamps.
              </li>
              <li>
                Allow users to choose profile photos using the camera or device
                gallery.
              </li>
            </ul>

            <h2>3. QR Code Scanning by Employees</h2>
            <p>
              Business employees have the option to use the camera exclusively
              to scan QR codes related to the Mostacho application, for example,
              to manage the loyalty system or process payments.
            </p>

            <h2>4. Sharing and Disclosing Information</h2>
            <p>
              We will not share your personal information with third parties,
              except in the following situations:
            </p>
            <ul>
              <li>With your explicit consent.</li>
              <li>To comply with legal obligations.</li>
              <li>
                In connection with a business transaction, such as a merger or
                acquisition.
              </li>
            </ul>

            <h2>5. Access and Control of Your Information</h2>
            <p>
              You can access and update your personal information, including the
              profile photo, through the Mostacho application. You can also
              manage your notification and privacy preferences in the
              application settings.
            </p>

            <h2>6. Security</h2>
            <p>
              We implement security measures to protect your information against
              unauthorized access and disclosure.
            </p>

            <h2>7. Third-Party Services</h2>
            <p>
              The application uses authentication services provided by third
              parties, such as Apple and Google, to facilitate the login
              process. By choosing to use these services, users agree to the
              terms and conditions of the respective external service providers.
            </p>

            <h2>8. Account Deletion</h2>
            <p>
              Users have the right to delete their accounts and personal data
              within the application at any time. To delete your account, please
              follow the steps provided in the application's settings or contact
              our support team for assistance.
            </p>

            <h2>9. Changes to This Privacy Policy</h2>
            <p>
              We reserve the right to update this Privacy Policy at any time.
              The date of the last revision will be indicated at the top of the
              policy.
            </p>
            <h2>10. Contact Us</h2>
            <p>
              If you have questions about this Privacy Policy, you can contact
              us at <a href="mailto:info@wasabihn.com">info@wasabihn.com</a>.
            </p>
          </div>
        </Content>
      </Layout>
    </Card>
  );
};

export default PrivacyEn;
