import React, { Key, useEffect, useState } from "react";
import IService from "@interfaces/IService";
import TableToolbar from "@components/Table/TableToolbar";
import ImageThumbnail from "@components/Common/ImageThumbnail";
import ActionComponent from "@components/Table/ActionComponent";

import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Col, Card, Table, Layout, message, Checkbox } from "antd";
import { isFilterMatched, sortAlphaNum, sortBoolean } from "@utils/util";
import {
  deleteService,
  getServices,
  updateServices,
} from "@services/serviceService";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const { Header, Content } = Layout;

const Services: React.FC = () => {
  const [data, setData] = useState<IService[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IService>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const serviceData = await getServices();
      setData(serviceData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      message.error(error?.response?.data);
    }
  };

  const columns: ColumnsType<IService> = [
    {
      key: "sort",
    },
    {
      key: "image",
      title: "Imagen",
      dataIndex: "image",
      width: 200,
      render: (image: any) => <ImageThumbnail folder="service" image={image} />,
    },
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      width: 400,
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "duration",
      title: "Duración",
      dataIndex: "duration",
      sorter: (a, b) => a.duration - b.duration,
      sortOrder: sortedInfo.columnKey === "duration" ? sortedInfo.order : null,
      render: (duration) => `${duration} min.`,
    },
    {
      key: "price",
      title: "Precio",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      sortOrder: sortedInfo.columnKey === "price" ? sortedInfo.order : null,
      render: (price) => `${price} lps.`,
    },
    {
      key: "redeemable",
      title: "Redimible",
      dataIndex: "redeemable",
      align: "center",
      sorter: (a, b) => sortBoolean(a.redeemable, b.redeemable),
      sortOrder:
        sortedInfo.columnKey === "redeemable" ? sortedInfo.order : null,
      render: (redeemable: any) => <Checkbox checked={redeemable} />,
    },
    {
      align: "center",
      width: 150,
      render: (service: IService) => (
        <ActionComponent
          label="service"
          dataObject={service}
          onDelete={onDelete}
          disabled={false}
          showEdit
          showDelete
        />
      ),
    },
  ];

  const defaultExpandable = {
    expandedRowRender: (record: any) => (
      <>
        <Row>
          <Col span={16}>
            <div>
              <b>Descripción: </b>
              <span>{record.description}</span>
            </div>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <b>Creado por: </b> <span>{record.metadata.createdBy}</span>
            </div>
            <br />
            <div>
              <b>Modificado por: </b> <span>{record.metadata.modifiedBy}</span>
            </div>
            <br />
          </Col>
        </Row>
      </>
    ),
  };

  const onDelete = async (service: IService) => {
    try {
      setLoading(true);
      await deleteService(service.id);
      await fetchData();
      setLoading(false);
      message.success("Service borrado exitosamente!");
    } catch (error: any) {
      setLoading(false);
      message.error(error.response.data);
    }
  };

  const handleTableChange: TableProps<IService>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IService>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    try {
      setLoading(true);
      if (active.id !== over?.id) {
        const activeIndex = data.findIndex((i) => i.id === active.id);
        const overIndex = data.findIndex((i) => i.id === over?.id);
        const newArray = arrayMove(data, activeIndex, overIndex);
        newArray.forEach((x, index) => (x.position = index));
        setData(newArray);
        await updateServices(newArray);
      }
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const Row = ({ children, ...props }: any) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar
            onFilterChange={handleFilterChange}
            canCreate
            properties={{ newPosition: data.length }}
          />
        </Header>
        <Content>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={data.map((i) => i.id)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={defaultExpandable}
                onChange={handleTableChange}
              />
            </SortableContext>
          </DndContext>
        </Content>
      </Layout>
    </Card>
  );
};

export default Services;
