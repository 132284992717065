import axios from "axios";
import React, { useEffect, useState } from "react";
import MenuComponent from "@components/Menu";


import { verifyAuth } from "@services/authService";
import { Breadcrumb, Layout, Image, message } from "antd";
import { breadcrumbNameMap } from "@constants/breadcrumbMap";
import { PublicContent, PrivateContent } from "@components/Content";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

const { Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);
  const [isCollapsed, setIsCollapsed] = useState<any>(false);

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const currentPath = location.pathname;
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const isForm = url.includes("form");
    let prefix = "";
    if (isForm) {
      prefix = location.state.dataObject ? "Editar " : "Crear ";
    }
    const label = prefix + breadcrumbNameMap[url];

    return {
      key: url,
      title: currentPath === url ? label : <Link to={url}>{label}</Link>,
    };
  });

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const auth = getAuth();
          await signOut(auth);
          navigate("/");
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const token = await user.getIdTokenResult();
          if (token && token.claims.role !== "Admin") {
            await signOut(auth);
            message.error("Permisos invalidos");
            setUser(null);
          } else {
            await verifyAuth();
            setUser(user);
          }
        } else {
          setUser(null);
        }
      } catch (error: any) {
        message.error(error.response.data);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Layout>
      {user !== null ? (
        <Layout>
          <Sider
            collapsible
            collapsedWidth={80}
            onCollapse={(collapsed) => setIsCollapsed(collapsed)}
            trigger={
              isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
            }
          >
            <div style={{ textAlign: "center" }}>
              <Image
                preview={false}
                width={80}
                height={80}
                src="./mostacho-logo.png"
              />
            </div>
            <MenuComponent />
          </Sider>
          <Layout style={{ marginLeft: 20 }}>
            <Breadcrumb
              className="breadcrumb-class"
              items={breadcrumbItems}
            ></Breadcrumb>
            <Content className="content-class">
              <PrivateContent />
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Mostacho Barber Shop ©2023 created by Wasabi
            </Footer>
          </Layout>
        </Layout>
      ) : null}

      {user === null ? <PublicContent /> : null}
    </Layout>
  );
};

export default App;
