import moment from "moment";
import { getDateTimeString } from "./date";

export const isFilterMatched = (value: any, record: any) => {
  const flatArray = Object.values(flattenObject(record));
  return flatArray?.some((x: any) => {
    return getFormattedValue(x)?.toLowerCase()?.includes(value?.toLowerCase());
  });
};

const getFormattedValue = (value: any) => {
  let formattedValue = value;
  if (typeof value === "number") {
    formattedValue = value.toString();
  } else {
    const date = moment(value);
    formattedValue = date.isValid() ? getDateTimeString(value) : value;
  }
  return formattedValue;
};

export const flattenObject = (obj: any) => {
  const result: any = {};

  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") Object.assign(result, val);
    else result[key] = val;
  });

  return result;
};

export const sortAlphaNum = (a: string, b: string) =>
  a?.localeCompare(b, "en", { numeric: true });

export const sortBoolean = (a: boolean, b: boolean) =>
  a === b ? 0 : a ? -1 : 1;

export const getPhoneNumber = (data: any) =>
  `+${data.phonePrefix} ${data.phone}`;

export const getDataWithImage = (data: any) => {
  if (data.image) {
    const { uid, name } = data.image[0];
    data.image = [
      {
        uid,
        name,
      },
    ];
  }
  return data;
};
