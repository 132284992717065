export const FIREBASE_API_KEY = process.env.REACT_APP_API_KEY || '';
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || '';
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_PROJECT_ID || '';
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET || '';
export const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID || '';
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_MESSAGING_SENDER_ID || '';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || '';
export const MAP_BOX_API = process.env.REACT_APP_MAP_BOX_API || '';
