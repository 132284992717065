import moment from "moment";
import es from "moment/locale/es";
import IUser from "@interfaces/IUser";
import IFieldData from "@interfaces/IFieldData";
import React, { useEffect, useState } from "react";
import FileUpload from "@components/Common/FileUpload";
import DatePicker from "@components/Common/DatePicker";
import PhonePrefix from "@components/Common/PhonePrefix";

import { DATE_FORMAT } from "@constants/date";
import { getDataWithImage } from "@utils/util";
import { getDate, getDateString } from "@utils/date";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser, updateUser } from "@services/userService";
import { buttonLayout, formLayout } from "@constants/formLayout";
import { Form, Input, Select, Space, message, Button, Spin, Card } from "antd";
import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

moment.locale("es", es);

const UserForm: React.FC<IFormProps> = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const user: IUser = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = () => {
    if (!user) return;
    setLoading(true);
    user.birthDate = getDate(user.birthDate);
    const userData = getFieldData(user);
    setFields(userData);
    setLoading(false);
  };

  const goBack = () => navigate("..");

  const onFinish = async (userData: IUser) => {
    try {
      setLoading(true);
      userData.birthDate = getDateString(userData.birthDate);
      const data = getDataWithImage(userData);
      if (user?.id) {
        await updateUser(data);
        message.success("Usuario actualizado!");
      } else {
        await createUser(data);
        message.success("Usuario creado!");
      }
      setLoading(false);
      goBack();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card>
        <Form
          form={form}
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="uid" hidden />
          <Form.Item name="stamps" hidden />
          <Form.Item name="lastStamp" hidden />
          <Form.Item name="role" hidden />
          <Form.Item
            name="firstName"
            label="Nombre"
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Apellido"
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Correo"
            rules={[
              {
                type: "email",
                message: "Correo no es valido.",
                required: true
              },
            ]}
          >
            <Input className="input-class" maxLength={50} />
          </Form.Item>

          {!props.excludeFields && (
            <>
              <Form.Item
                name="phone"
                label="Teléfono"
                rules={[
                  {
                    len: 8,
                    message: "Teléfono debe de contener 8 numeros",
                  },
                ]}
              >
                <Input addonBefore={<PhonePrefix />} maxLength={8} />
              </Form.Item>
              <Form.Item name="birthDate" label="Nacimiento">
                <DatePicker
                  format={DATE_FORMAT}
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder="Elegir fecha"
                />
              </Form.Item>

              <Form.Item name="role" label="Role" initialValue="Cliente">
                <Select>
                  <Select.Option value="Cliente">Cliente</Select.Option>
                  <Select.Option value="Admin">Admin</Select.Option>
                </Select>
              </Form.Item>

              <FileUpload
                folder="user"
                form={form}
                image={user && user.image}
              />
            </>
          )}

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {user?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default UserForm;
