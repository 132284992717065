import "./App.css";
import "./styles/antTable.css";
import 'mapbox-gl/dist/mapbox-gl.css';

import React from "react";
import locale from "antd/locale/es_ES";
import Dashboard from "@pages/Dashboard";

import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { PRIMARY_COLOR } from "@constants/globalVariables";

const App: React.FC = () => (
  <ConfigProvider
    locale={locale}
    theme={{
      token: {
        colorPrimary: PRIMARY_COLOR,
      },
    }}
  >
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
