import React, { Key, useEffect, useState } from "react";
import IBusiness from "@interfaces/IBusiness";
import TableToolbar from "@components/Table/TableToolbar";
import ImageThumbnail from "@components/Common/ImageThumbnail";
import ActionComponent from "@components/Table/ActionComponent";

import { SorterResult } from "antd/es/table/interface";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Table, Layout, Card, message, Row, Col } from "antd";
import { deleteBusiness, getBusinesses } from "@services/businessService";

const { Header, Content } = Layout;

const Business: React.FC = () => {
  const [data, setData] = useState<IBusiness[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IBusiness>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const businessData = await getBusinesses();
      setData(businessData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      message.error(error?.response?.data);
    }
  };

  const columns: ColumnsType<IBusiness> = [
    {
      key: "image",
      title: "Imagen",
      dataIndex: "image",
      width: 100,
      render: (image: any) => (
        <ImageThumbnail folder="business" image={image} />
      ),
    },
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      title: "Teléfono",
      sorter: (a, b) => sortAlphaNum(a.phone, b.phone),
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
      render: (user: IBusiness) => `${user.phonePrefix} ${user.phone}`,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortAlphaNum(a.email, b.email),
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
    },
    {
      align: "center",
      width: 150,
      render: (business: IBusiness) => (
        <ActionComponent
          label="business"
          dataObject={business}
          onDelete={onDelete}
          disabled={false}
          showEdit
          showDelete
        />
      ),
    },
  ];

  const defaultExpandable = {
    expandedRowRender: (record: any) => (
      <>
        <Row>
          <Col span={16}>
            <div>
              <b>Dirección: </b>
              <span>{record.address}</span>
            </div>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <b>Creado por: </b> <span>{record.metadata.createdBy}</span>
            </div>
            <br />
            <div>
              <b>Modificado por: </b> <span>{record.metadata.modifiedBy}</span>
            </div>
            <br />
          </Col>
        </Row>
      </>
    ),
  };

  const onDelete = async (business: IBusiness) => {
    try {
      setLoading(true);
      await deleteBusiness(business.id);
      await fetchData();
      setLoading(false);
      message.success("Barbería borrada exitosamente!");
    } catch (error: any) {
      setLoading(false);
      message.error(error.response.data);
    }
  };

  const handleTableChange: TableProps<IBusiness>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IBusiness>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={defaultExpandable}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default Business;
