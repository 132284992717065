import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "@constants/date";

export const getNow = () => moment();

export const getDate = (date: string) => {
  if (!date) return null;
  return moment(date, DATE_FORMAT);
};

export const getDateTime = (date: string) => {
  if (!date) return null;
  return moment(date, DATE_TIME_FORMAT);
};

export const getDateString = (date: any) => {
  if (!date) return "";
  return moment(date, DATE_TIME_FORMAT).format(DATE_FORMAT);
};

export const getDateTimeString = (date: string) => {
  if (!date) return "";
  return moment(date, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT);
};

export const getDateDifference = (dateA: string, dateB: string) =>
  moment(dateA, DATE_TIME_FORMAT).diff(moment(dateB, DATE_TIME_FORMAT));
