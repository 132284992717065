import React, { Key, useEffect, useState } from "react";
import { getRoleColor } from "@components/Table/table";
import { SorterResult } from "antd/es/table/interface";
import { Table, Layout, Card, message, Tag } from "antd";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import { deleteUser, getUsers } from "@services/userService";
import type { ColumnsType, TableProps } from "antd/es/table";

import IUser from "@interfaces/IUser";
import TableToolbar from "@components/Table/TableToolbar";
import ImageThumbnail from "@components/Common/ImageThumbnail";
import ActionComponent from "@components/Table/ActionComponent";

const { Header, Content } = Layout;

const Users: React.FC = () => {
  const [data, setData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IUser>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const userData = await getUsers();
      setData(userData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      message.error(error?.response?.data);
    }
  };

  const columns: ColumnsType<IUser> = [
    {
      key: "image",
      title: "Imagen",
      dataIndex: "image",
      width: 100,
      render: (image: any) => <ImageThumbnail folder='user' image={image} />,
    },
    {
      title: "Nombre completo",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.firstName, b.firstName),
      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      render: (user: IUser) => `${user.firstName} ${user.lastName}`,
    },
    {
      title: "Teléfono",
      sorter: (a, b) => sortAlphaNum(a.phone, b.phone),
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
      render: (user: IUser) => `${user.phonePrefix} ${user.phone}`,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortAlphaNum(a.email, b.email),
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
    },
    {
      key: "birthDate",
      title: "Fecha de nacimiento",
      dataIndex: "birthDate",
      sorter: (a, b) => sortAlphaNum(a.birthDate, b.birthDate),
      sortOrder: sortedInfo.columnKey === "birthDate" ? sortedInfo.order : null,
    },
    {
      key: "stamps",
      title: "Sellos",
      dataIndex: "stamps",
      sorter: (a, b) => a.stamps - b.stamps,
      sortOrder: sortedInfo.columnKey === "stamps" ? sortedInfo.order : null,
    },
    {
      key: "role",
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => sortAlphaNum(a.role, b.role),
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      render: (role) => <Tag color={getRoleColor(role)}>{role}</Tag>,
    },
    {
      align: "center",
      width: 150,
      render: (user: IUser) => (
        <ActionComponent
          label="usuario"
          dataObject={user}
          onDelete={onDelete}
          disabled={false}
          showEdit
          showDelete
        />
      ),
    },
  ];

  const handleTableChange: TableProps<IUser>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IUser>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  const onDelete = async (user: IUser) => {
    try {
      setLoading(true);
      await deleteUser(user.uid);
      await fetchData();
      setLoading(false);
      message.success("Usuario borrado exitosamente!");
    } catch (error: any) {
      setLoading(false);
      message.error(error.response.data);
    }
  };

  const defaultExpandable = {
    expandedRowRender: (record: any) => (
      <>
        <div>
          <b>Creado por: </b> <span>{record.metadata.createdBy}</span>
        </div>
        <div>
          <b>Modificado por: </b> <span>{record.metadata.modifiedBy}</span>
        </div>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={defaultExpandable}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default Users;
