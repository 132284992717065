import { Form, Input } from "antd";
import FileUpload from "@components/Common/FileUpload";
import PhonePrefix from "@components/Common/PhonePrefix";

const { TextArea } = Input;

const Information = ({ form, image }: any) => {
  return (
    <>
      <Form.Item name="id" hidden />

      <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
        <Input className="input-class" maxLength={50} />
      </Form.Item>

      <Form.Item name="address" label="Dirección">
        <TextArea rows={3} maxLength={100} />
      </Form.Item>

      <Form.Item
        name="email"
        label="Correo"
        rules={[
          {
            type: "email",
            message: "Correo no es valido.",
          },
        ]}
      >
        <Input className="input-class" maxLength={50} />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Teléfono"
        rules={[
          {
            len: 8,
            message: "Teléfono debe de contener 8 numeros",
          },
        ]}
      >
        <Input addonBefore={<PhonePrefix />} maxLength={8} />
      </Form.Item>

      <FileUpload folder="business" form={form} image={image} />
    </>
  );
};

export default Information;
