import IFieldData from "@interfaces/IFieldData";
import { message } from "antd";

export interface IFormProps {
  dataObject?: any;
  properties?: any;
  onReload?: Function;
  onCancel?: Function;
  excludeFields?: boolean;
}

export interface IRenderItem {
  key: string;
  label: string;
  value: string;
}

export const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
};

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    sm: { span: 16, offset: 8 },
  },
};

export const onFinishFailed = () => {
  message.error("Llenar todos los campos requeridos!");
};

export const getFieldData = (fieldData: any): IFieldData[] =>
  Object.entries(fieldData)?.map(([key, value]: any) => ({
    name: key,
    value: value?.key ? value.key : value,
  }));

export const renderItem = (value: any) => ({
  key: value.id,
  label: `${value.firstName} ${value.lastName}`,
  value: value.id,
});
