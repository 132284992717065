import { FormLayout } from "antd/es/form/Form";
import type { FormLabelAlign } from "antd/es/form/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";

export const formLayout = {
  colon: false,
  labelWrap: true,
  autoComplete: "off",
  size: "large" as SizeType,
  layout: "horizontal" as FormLayout,
  labelAlign: "left" as FormLabelAlign,
  validateMessages: { required: " " },
  labelCol: { span: 8 },
  style: { maxWidth: 700 },
  wrapperCol: { span: 16 },
};

export const buttonLayout = {
  wrapperCol: { offset: 8 },
  style: { marginTop: 20 },
};
