import axios from "axios";
import IService from "@interfaces/IService";
import { getConfigHeaders } from "@configs/axios";

const API_BASE_URL = "/api/service";

export const getService = async (id: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching service:", error);
    throw error;
  }
};

export const getServices = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/all`, getConfigHeaders());
    return response.data;
  } catch (error) {
    console.error("Error fetching services:", error);
    throw error;
  }
};

export const createService = async (serviceData: IService) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      serviceData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating service:", error);
    throw error;
  }
};

export const updateService = async (serviceData: IService) => {
  try {
    const { id } = serviceData;
    const response = await axios.put(
      `${API_BASE_URL}/update/${id}`,
      serviceData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating service:", error);
    throw error;
  }
};

export const updateServices = async (servicesData: IService[]) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/batch`,
      servicesData,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating service:", error);
    throw error;
  }
};

export const deleteService = async (id: string) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/${id}`,
      getConfigHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting service:", error);
    throw error;
  }
};
