import React, { useEffect, useState } from "react";
import IUser from "@interfaces/IUser";
import IStamp from "@interfaces/IStamp";
import IFieldData from "@interfaces/IFieldData";
import DatePicker from "@components/Common/DatePicker";

import { getUsers } from "@services/userService";
import { getNow, getDateTime } from "@utils/date";
import { DATE_TIME_FORMAT } from "@constants/date";
import { createStamp } from "@services/stampService";
import { useLocation, useNavigate } from "react-router-dom";
import { buttonLayout, formLayout } from "@constants/formLayout";
import { Form, Select, Space, message, Button, Spin, Card } from "antd";
import {
  IFormProps,
  IRenderItem,
  getFieldData,
  onFinishFailed,
  renderItem,
} from "@components/Form/form";

const UserForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const stamp: IStamp = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [userOptions, setUserOptions] = useState<IRenderItem[]>([]);
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchUsersData();
    loadStamps();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStamps = () => {
    if (!stamp) return;
    setLoading(true);
    stamp.stampAt = getDateTime(stamp.stampAt);
    const stampData = getFieldData(stamp);
    setFields(stampData);
    setLoading(false);
  };

  const fetchUsersData = async () => {
    try {
      const users = await getUsers();
      const userOptions: IRenderItem[] = users?.map((user: IUser) =>
        renderItem({
          id: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
        })
      );
      setUserOptions(userOptions);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data);
      return [];
    }
  };

  const goBack = () => navigate("..");

  const onFinish = async (stampData: any) => {
    try {
      setLoading(true);
      const { uid, quantity } = stampData;
      await createStamp(uid, quantity);
      message.success("Sello creado!");

      setLoading(false);
      goBack();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data?.message || 'Error');
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card>
        <Form
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="stampAt" label="Fecha" initialValue={getNow()}>
            <DatePicker disabled format={DATE_TIME_FORMAT} />
          </Form.Item>

          <Form.Item name="uid" label="Cliente" rules={[{ required: true }]}>
            <Select
              showSearch
              options={userOptions}
              notFoundContent="Usuario no encontrado"
              filterOption={(inputValue: any, option: any) =>
                option!.label
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            ></Select>
          </Form.Item>

          <Form.Item
            name="quantity"
            label="Cantidad"
            initialValue={1}
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value={1}>1 Sellos</Select.Option>
              <Select.Option value={2}>2 Sellos</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {"Crear"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default UserForm;
